<template>
	<div class="page">
		<Tables ref="xTable" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.pack/add')" addName="新增券包" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.key" size="small" clearable placeholder="按券包名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="pack_name" title="券包名称" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="original_cost" title="原价" align="center" min-width="20px" />
			<vxe-table-column slot="table-item" field="price" title="现价" align="center" min-width="20px" />
			<vxe-table-column slot="table-item" title="有效时间" align="center" min-width="160px">
				<template v-slot="{ row }">
					<span>{{ row.start_time }} - {{ row.end_time }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="sort_num" title="排序↑" align="center" min-width="20px" />
			<vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" title="操作" align="center" min-width="50px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.pack/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('market.pack/del')">删除
					</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增 -->
		<el-dialog title="新增券包" :visible.sync="addDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="650px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="120px" class="form">
				<el-form-item label="券包名称" prop="pack_name">
					<el-input v-model="addDialogFormData.pack_name" style="width: 45%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="券包描述" prop="details">
					<el-input v-model="addDialogFormData.details" type="textarea" rows='5' clearable
						style="width:80%" />
				</el-form-item>
				<el-form-item label="原价" prop="original_cost">
					<el-input-number :precision="2" :min="0" v-model="addDialogFormData.original_cost" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="现价" prop="price">
					<el-input-number :precision="2" :min="0" v-model="addDialogFormData.price" clearable size="small" />
				</el-form-item>
				<el-form-item label="券包内容" prop="rewards">
					<div class="coupon-choose-form">
						<div class="title">
							<span class="tips"></span>
							<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
								@click="onItemAdd(addDialogFormData)">增加优惠券</el-button>
						</div>
						<div v-for="reward in addDialogFormData.rewards" class="coupon-item">
							<el-select v-model="reward.coupon" class="select" size="small" placeholder="请选择优惠券"
								clearable>
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name"
									:value="item.coupon_id">
								</el-option>
							</el-select>
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="reward.num" class="input" size="small" placeholder="请输入数量">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
								@click="onItemDel(addDialogFormData,idx)">删除</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="排序(小排前)" prop="sort_num">
					<el-input-number :precision="0" :min="0" v-model="addDialogFormData.sort_num" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="券包时间" prop="rangeTime">
					<el-date-picker v-model="addDialogFormData.rangeTime" @input="dateSet" value-format="yyyy-MM-dd"
						type="daterange" size="small" clearable>
					</el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
					v-if="$hasAccess('market.pack/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑券包" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="650px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="120px" class="form">
				<el-form-item label="券包名称" prop="pack_name">
					<el-input v-model="editDialogFormData.pack_name" style="width: 45%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="券包描述" prop="details">
					<el-input v-model="editDialogFormData.details" type="textarea" rows='5' clearable
						style="width:80%" />
				</el-form-item>
				<el-form-item label="原价" prop="original_cost">
					<el-input-number :precision="2" :min="0" v-model="editDialogFormData.original_cost" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="现价" prop="price">
					<el-input-number :precision="2" :min="0" v-model="editDialogFormData.price" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="券包内容" prop="rewards">
					<div class="coupon-choose-form">
						<div class="title">
							<span class="tips"></span>
							<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
								@click="onItemAdd(editDialogFormData)">增加优惠券</el-button>
						</div>
						<div v-for="reward in editDialogFormData.rewards" class="coupon-item">
							<el-select v-model="reward.coupon" class="select" size="small" placeholder="请选择优惠券"
								clearable>
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name"
									:value="item.coupon_id">
								</el-option>
							</el-select>
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="reward.num" class="input" size="small" placeholder="请输入数量">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
								@click="onItemDel(editDialogFormData,idx)">删除</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="排序(小排前)" prop="sort_num">
					<el-input-number :precision="0" :min="0" v-model="editDialogFormData.sort_num" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="券包时间" prop="rangeTime">
					<el-date-picker v-model="editDialogFormData.rangeTime" @input="dateSet" value-format="yyyy-MM-dd"
						type="daterange" size="small" clearable>
					</el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
					v-if="$hasAccess('market.pack/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'market-pack-list',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				isLoading: false,
				tableData: [],
				totalPage: 0,
				imageList: [],
				couponList: [],
				searchFormData: {},
				addDialogShow: false, // 新增
				addDialogFormData: {
					rewards: []
				},
				rules: {
					pack_name: [{
						required: true,
						message: '请输入券包名称',
						trigger: 'change'
					}],
					details: [{
						required: true,
						message: '请输入券包描述',
						trigger: 'change'
					}],
					original_cost: [{
						required: true,
						message: '请输入原价',
						trigger: 'change'
					}],
					price: [{
						required: true,
						message: '请输入现价',
						trigger: 'change'
					}],
					rewards: [{
						required: true,
						message: '请选择券包内容',
						trigger: 'change'
					}],
					sort_num: [{
						required: true,
						message: '请填写排序',
						trigger: 'change'
					}],
					rangeTime: [{
						required: true,
						message: '请输入有效时间',
						trigger: 'change'
					}],
				},
				editDialogShow: false, // 编辑
				editDialogFormData: {
					rewards: []
				},
				editNeedData: ['id', 'pack_name']
			}
		},
		computed: {
			viewImgList() {
				return function(row) {
					return [row.icon]
				}
			}
		},
		methods: {
			dateSet(v) { //设置日期范围
				this.$forceUpdate();
			},
			getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				let searchParams = {
					_keywords: this.searchFormData.key
				};
				this.isLoading = true
				this.$api.Market.GetPackList(params, searchParams).then(res => {
					this.totalPage = res.total
					this.tableData = res.data
					for (let d of this.tableData) {
						d.rangeTime = [d.start_time, d.end_time];
					}
					this.isLoading = false
				}).catch(err => {
					this.isLoading = false
				})
			},
			clearSearch() {
				this.searchFormData = {
					key: '',
				}
			},
			// 重置表单
			resetFormData() {
				this.addDialogFormData = {
					rewards: []
				};
				this.imageList = [];
				this.editDialogFormData = {};
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields();
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields();
				}
				this.getCouponList();
			},
			//添加
			addOne(row) {
				this.resetFormData();
				this.addDialogShow = true;
			},
			//编辑
			editOne(row) {
				this.resetFormData();
				this.editDialogShow = true;
				this.editDialogFormData = Object.assign({}, row);
			},
			//删除
			deleteOne(row) {
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				this.$api.Market.DeletePack(params).then(() => {
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				});
			},
			// 新增保存
			saveAdd() {
				this.$refs.addForm.validate((vali) => {
					if (!vali) return;
					this.addDialogFormData.start_time = this.addDialogFormData.rangeTime[0];
					this.addDialogFormData.end_time = this.addDialogFormData.rangeTime[1];
					const form = {
						pack_name: this.addDialogFormData.pack_name,
						details: this.addDialogFormData.details,
						original_cost: this.addDialogFormData.original_cost,
						price: this.addDialogFormData.price,
						sort_num: this.addDialogFormData.sort_num,
						start_time: this.addDialogFormData.start_time,
						end_time: this.addDialogFormData.end_time,
						rewards: this.addDialogFormData.rewards,
					}
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(form),
					}
					this.$api.Market.AddPack(params).then(() => {
						this.$notify({
							title: "成功",
							message: "添加成功",
							type: "success",
						});
						this.closeDialog();
						this.$refs.xTable.refreshTable()
					});
				})
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate((vali) => {
					if (!vali) return;
					this.editDialogFormData.start_time = this.editDialogFormData.rangeTime[0];
					this.editDialogFormData.end_time = this.editDialogFormData.rangeTime[1];
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(this.editDialogFormData)
					}
					this.$api.Market.EditPack(params).then(() => {
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					});
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false
				this.editDialogShow = false
			},
			// 获取优惠券信息
			async getCouponList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
				};
				const res = await this.$api.Market.GetCouponList(params, {});
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;
			},
			//增加
			onItemAdd: function(item) {
				item.rewards.push({
					"coupon": null,
					"num": 1
				});
			},
			//删除
			onItemDel: function(item, idx) {
				item.rewards.splice(idx, 1)
			}
		}
	}
</script>
<style>
	.coupon-choose-form {
		.coupon-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 5px;
				width: 80px;
			}

			.input {
				margin-left: 5px;
				width: 80px;
			}

			.del-button {
				margin-left: 10px;
			}
		}
	}
</style>
